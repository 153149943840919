@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "/src/ui/styles/globals.scss";

h1 {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesH1size);
  font-weight: 400;
  line-height: var(--fontstylesH1lineheight);
  letter-spacing: var(--fontstylesH1letterspacing);
  text-align: left;
}

h2 {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesH2size);
  font-weight: 400;
  line-height: var(--fontstylesH2lineheight);
  letter-spacing: var(--fontstylesH2letterspacing);
  text-align: left;
}

h3 {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesH3size);
  font-weight: 400;
  line-height: var(--fontstylesH3lineheight);
  letter-spacing: var(--fontstylesH3letterspacing);
  text-align: left;
}

h4 {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesH4size);
  font-weight: 400;
  line-height: var(--fontstylesH4lineheight);
  letter-spacing: var(--fontstylesH4letterspacing);
  text-align: left;
}

h5 {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesH5size);
  font-weight: 500;
  line-height: var(--fontstylesH5lineheight);
  letter-spacing: var(--fontstylesH5letterspacing);
  text-align: left;
}

h6 {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesH6size);
  font-weight: 500;
  line-height: var(--fontstylesH6lineheight);
  letter-spacing: var(--fontstylesH6letterspacing);
  text-align: left;
}

p {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesBodysize);
  font-weight: 400;
  line-height: var(--fontstylesBodylineheight);
  letter-spacing: var(--fontstylesBodyletterspacing);
  text-align: left;
}

/* Custom classes for specific styles */
.display1 {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesDisplay1size);
  font-weight: 500;
  line-height: var(--fontstylesDisplay1lineheight);
  letter-spacing: var(--fontstylesDisplay1letterspacing);
  text-align: left;
}

.display2 {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesDisplay2size);
  font-weight: 500;
  line-height: var(--fontstylesDisplay2lineheight);
  letter-spacing: var(--fontstylesDisplay2letterspacing);
  text-align: left;
}

.display3 {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesDisplay3size);
  font-weight: 500;
  line-height: var(--fontstylesDisplay3lineheight);
  letter-spacing: var(--fontstylesDisplay3letterspacing);
  text-align: left;
}

.body {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesBodysize);
  font-weight: 400;
  line-height: var(--fontstylesBodylineheight);
  letter-spacing: var(--fontstylesBodyletterspacing);
  text-align: left;
}

.body-dense {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesBodydensesize);
  font-weight: 400;
  line-height: var(--fontstylesBodydenselineheight);
  letter-spacing: var(--fontstylesBodydenseletterspacing);
  text-align: left;
}

.body-emph1 {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesBodyemph1size);
  font-weight: 500;
  line-height: var(--fontstylesBodyemph1lineheight);
  letter-spacing: var(--fontstylesBodyemph1letterspacing);
  text-align: left;
}

.body-emph1-dense {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesBodyemph1densesize);
  font-weight: 500;
  line-height: var(--fontstylesBodyemph1denselineheight);
  letter-spacing: var(--fontstylesBodyemph1denseletterspacing);
  text-align: left;
}

.body-emph2 {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesBodyemph2size);
  font-weight: 600;
  line-height: var(--fontstylesBodyemph2lineheight);
  letter-spacing: var(--fontstylesBodyemph2letterspacing);
  text-align: left;
}

.body-emph2-dense {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesBodyemph2densesize);
  font-weight: 600;
  line-height: var(--fontstylesBodyemph2denselineheight);
  letter-spacing: var(--fontstylesBodyemph2denseletterspacing);
  text-align: left;
}

.caption {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesCaptionsize);
  font-weight: 400;
  line-height: var(--fontstylesCaptionlineheight);
  letter-spacing: var(--fontstylesCaptionletterspacing);
  text-align: left;
}

.caption-dense {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesCaptiondensesize);
  font-weight: 400;
  line-height: var(--fontstylesCaptiondenselineheight);
  letter-spacing: var(--fontstylesCaptiondenseletterspacing);
  text-align: left;
}

.caption-emph {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesCaptionemphsize);
  font-weight: 600;
  line-height: var(--fontstylesCaptionemphlineheight);
  letter-spacing: var(--fontstylesCaptionemphletterspacing);
  text-align: left;
}

.caption-emph-dense {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesCaptionemphdensesize);
  font-weight: 600;
  line-height: var(--fontstylesCaptionemphdenselineheight);
  letter-spacing: var(--fontstylesCaptionemphdenseletterspacing);
  text-align: left;
}

.footnote {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesFootnotesize);
  font-weight: 400;
  line-height: var(--fontstylesFootnotelineheight);
  letter-spacing: var(--fontstylesFootnoteletterspacing);
  text-align: left;
}

.footnote-dense {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesFootnotedensesize);
  font-weight: 400;
  line-height: var(--fontstylesFootnotedenselineheight);
  letter-spacing: var(--fontstylesFootnotedenseletterspacing);
  text-align: left;
}

.footnote-emph {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesFootnoteemphsize);
  font-weight: 600;
  line-height: var(--fontstylesFootnoteemphlineheight);
  letter-spacing: var(--fontstylesFootnoteemphletterspacing);
  text-align: left;
}

.footnote-emph-dense {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesFootnoteemphdensesize);
  font-weight: 600;
  line-height: var(--fontstylesFootnoteemphdenselineheight);
  letter-spacing: var(--fontstylesFootnoteemphdenseletterspacing);
  text-align: left;
}

.label-small {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesLabelsmallsize);
  font-weight: 500;
  line-height: var(--fontstylesLabelsmalllineheight);
  letter-spacing: var(--fontstylesLabelsmallletterspacing);
  text-align: center;
}

.label-small-hover {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesLabelsmallhoversize);
  font-weight: 500;
  line-height: var(--fontstylesLabelsmallhoverlineheight);
  letter-spacing: var(--fontstylesLabelsmallhoverletterspacing);
  text-align: center;
}

.label {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesLabelsize);
  font-weight: 500;
  line-height: var(--fontstylesLabellineheight);
  letter-spacing: var(--fontstylesLabelletterspacing);
  text-align: center;
}

.label-hover {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesLabelhoversize);
  font-weight: 500;
  line-height: var(--fontstylesLabelhoverlineheight);
  letter-spacing: var(--fontstylesLabelhoverletterspacing);
  text-align: center;
}

.label-large {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesLabellargesize);
  font-weight: 500;
  line-height: var(--fontstylesLabellargelineheight);
  letter-spacing: var(--fontstylesLabellargeletterspacing);
  text-align: center;
}

.label-large-hover {
  font-family: var(--font-sanserif);
  font-size: var(--fontstylesLabellargehoversize);
  font-weight: 500;
  line-height: var(--fontstylesLabellargehoverlineheight);
  letter-spacing: var(--fontstylesLabellargehoverletterspacing);
  text-align: center;
}

.username {
  font-family: var(--font-serif);
  font-size: var(--fontstylesUsernamesize);
  font-weight: 400;
  line-height: var(--fontstylesUsernamelineheight);
  letter-spacing: var(--fontstylesUsernameletterspacing);
  text-align: center;
}
